import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getCurrentUser} from 'pages/Login/slice'

class ProfilePicture extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        uploadedFile: PropTypes.object,
        small: PropTypes.bool,
    }
    static defaultProps = {
        user: null,
        uploadedFile: null,
        small: false,
    }

    render() {
        const profile = this.props?.profile
        return (
            <div className={'form__profile-image-container' + (this.props.small ? ' form__profile-image-container-small' : '')}>
                {this.props.uploadedFile !== null
                    ? (<img src={URL.createObjectURL(this.props.uploadedFile)} alt="" className="form__profile-image" />)
                    : profile?.picture
                        ? (<img src={profile?.picture} alt={profile?.full_name} className="form__profile-image" />)
                        : (<img src='/images/icon-account.png' alt="Geen profielfoto" className="form__profile-image" />)
                }
                {this.props.children}
            </div>
        )
    }
}

export default connect((state, ownProps) => ({
    profile: ownProps?.user?.profile
}))(ProfilePicture)