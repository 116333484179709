import React from 'react'
import {withOfflineDetection} from 'components/NetworkContext'
import Notification from 'components/Notification'

class OfflineNotification extends React.Component {

    render() {
        if (this.props.network.isOnline) {
            return null
        }

        return (
            <Notification type={'error'}>Je bent offline.</Notification>
        )
    }
}

export default withOfflineDetection(OfflineNotification)