import React from 'react'
import PropTypes from 'prop-types'

class Message extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['error', 'default', 'success']).isRequired,
    }

    render() {
        return (
            <div className={'form-result form-result-'+this.props.type}>
                {this.props.children}
            </div>
        )
    }
}

export default Message

export class ErrorMessage extends React.Component {
    static propTypes = {
        errors: PropTypes.object,
    }
    render() {
        // The 'message' key coming back from the API is not and never will be translated,
        // so we're not going to display it. https://github.com/laravel/framework/pull/32475

        return (
            <Message type='error'>
                {this.props?.errors && Object.keys(this.props?.errors).length > 0
                    ? (
                        <React.Fragment>
                            <h5>Foutmelding</h5>
                            {Object.keys(this.props?.errors).map(field => (
                                <div key={'error-field-' + field}>
                                    <ul>{this.props?.errors[field].map((k,i) => (<li key={'error-'+i}>{k}</li>))}</ul>
                                </div>
                            ))}
                        </React.Fragment>
                    )
                    : this.props.children
                }
            </Message>
        )
    }
}

export class SuccessMessage extends React.Component {
    render() {
        return (
            <Message type='success'>
                {this.props.children}
            </Message>
        )
    }
}