import {normalize} from 'normalizr'

/**
 * Check if we have a valid XSRF-TOKEN cookie, and try to refresh it if we don't.
 *
 * @returns {Promise<unknown>}
 */
export const validateCSRFcookie = () => {
    // see if we have an XSRF-TOKEN cookie
    let cookies = document.cookie ? document.cookie.split('; ') : []
    const key = 'xsrf-token'
    let token = null
    for (let i = 0; i < cookies.length; i++) {
        let parts = cookies[i].split('=')
        let value = parts.slice(1).join('=')

        if (value[0] === '"') {
            value = value.slice(1, -1)
        }

        try {
            let foundKey = parts[0].replace(/(%[\dA-F]{2})+/gi, decodeURIComponent).toLowerCase()

            if (foundKey === key) {
                token = value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent)
                break
            }
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    }

    return new Promise((resolve, reject) => {
        if (token !== null) {
            // we already have a token, we're good
            return resolve()
        }

        // we don't have a token, try to refresh it
        window.axios
            .get('../sanctum/csrf-cookie')
            .then(resolve) // refresh succeeded
            .catch(reject) // refresh failed
    })
}

const handleUnauthorized = thunkApi => error => {
    const notLoggedIn = error?.response?.status === 401
    const noContract = error?.response?.status === 403
    if (notLoggedIn || noContract) {
        // user is not logged in, or doesn't have a valid contract
        // -> redirect to '/' so that s/he can login
        if (window.location.pathname !== '/') {
            // ... but only if we're not on '/' already, to prevent redirect loop
            setTimeout(() => dispatchEvent(new CustomEvent(notLoggedIn ? 'dramaonline-api-loggedout' : 'dramaonline-api-nocontract')), 0)
            window.location.href = '/'
            return
        }
    }

    // we got an error
    return thunkApi.rejectWithValue(error?.response?.data || error?.response || error || 'Error in handleUnauthorized')
}

const handleRequest = (request, schema, thunkApi) =>
    validateCSRFcookie()
        .then(() => request()
            .then(response => ({
                normalized: schema && response.headers['content-type'].indexOf('json') > -1 ? normalize(response.data, schema) : null,
                data: response.data,
                meta: response.meta,
            }))
            .catch(handleUnauthorized(thunkApi)) // handle errors in 'request'
        ).catch(handleUnauthorized(thunkApi)) // handle errors in refreshing CSRF-token


const buildUrl = (url, params, appendRemaining = true) => {
    Object.keys(params).forEach(k => {
        if (url.indexOf(':' + k) > -1) {
            url = url.replace(':' + k, params[k])
            delete params[k]
        }
    })

    const encoded = Object.entries(params).map(kv => kv.map(encodeURIComponent).join('=')).join('&')
    return url + ((appendRemaining && encoded.length > 0) ? '?' + encoded : '')
}

export default class api {

    static post = (url, {schema, ...axiosConfig} = {}) => (obj = {}, thunkApi) =>
        handleRequest(() => axios.post(url, obj, axiosConfig), schema, thunkApi)

    static get = (url, {schema, ...axiosConfig} = {}) => (obj = {}, thunkApi) =>
        handleRequest(() => axios.get(buildUrl(url, obj), axiosConfig), schema, thunkApi)

    static delete = (url, {schema, ...axiosConfig} = {}) => (obj = {}, thunkApi) =>
        handleRequest(() => axios.delete(buildUrl(url, obj), axiosConfig), schema, thunkApi)
}
