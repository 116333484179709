import React from 'react'
import Notification from 'components/Notification'

class ServiceWorkerNotifications extends React.Component {
    state = {
        updateAvailable: false,
        appInstalled: false,
        skippedWaiting: false,
        loggedOut: false,
        noContract: false,
    }

    skipWaiting = () => {
        if (!this.state.skippedWaiting) {
            if (this?.registration?.waiting) {
                // trigger service worker's skipWaiting functionality to load the new update
                this.registration.waiting.postMessage({type: 'SKIP_WAITING'})
                this.setState({skippedWaiting: true, updateAvailable: false})
            }
        }
    }

    updateAvailable = (event) => {
        this.registration = event.detail.registration
        this.setState({updateAvailable: true})

        // we need to trigger the 'skip waiting' functionality of the service worker,
        // so that the update will actually be applied, but it's a bit iffy on when
        // this is actually possible. Once skipWaiting has completed, the page will
        // automatically reload.
        setTimeout(this.skipWaiting, 1000)
    }

    appInstalled = () => {
        this.setState({appInstalled: !this.state.appInstalled})
    }

    loggedOut = () => {
        this.setState({loggedOut: !this.state.loggedOut})
    }

    noContract = () => {
        this.setState({noContract: !this.state.noContract})
    }

    componentDidMount() {
        window.addEventListener('dramaonline-sw-update', this.updateAvailable)
        window.addEventListener('dramaonline-sw-success', this.appInstalled)
        window.addEventListener('dramaonline-api-loggedout', this.loggedOut)
        window.addEventListener('dramaonline-api-nocontract', this.noContract)
    }

    componentWillUnmount() {
        window.removeEventListener('dramaonline-sw-update', this.updateAvailable)
        window.removeEventListener('dramaonline-sw-success', this.appInstalled)
        window.removeEventListener('dramaonline-api-loggedout', this.loggedOut)
        window.removeEventListener('dramaonline-api-nocontract', this.noContract)
    }

    reload = (e) => {
        window.location.reload()
        e.preventDefault()
        e.stopPropagation()
        return false
    }

    render() {
        return (
            <React.Fragment>
                {/*this.state.appInstalled && ( // no need to tell people about this, why would they need to know?
                    <Notification timeout={5}><p>DramaOnline kan nu offline gebruikt worden.</p></Notification>
                )*/}
                {/*this.state.updateAvailable && (
                    <Notification>
                        <p>
                            Er is een nieuwe versie van DramaOnline beschikbaar.
                            Sluit alle open tabs en <a href={'#'} onClick={this.reload}>herlaad de pagina</a>.
                        </p>
                    </Notification>
                )*/}
                {this.state.loggedOut && (
                    <Notification type={'error'} timeout={5} onClose={this.loggedOut}>
                        <p>
                            Je bent uitgelogd.
                        </p>
                    </Notification>
                )}
                {this.state.noContract && (
                    <Notification type={'error'}>
                        <p>
                            Je hebt geen geldig contract! Je kunt daarom niet inloggen.
                        </p>
                    </Notification>
                )}
            </React.Fragment>
        )
    }
}

export default ServiceWorkerNotifications