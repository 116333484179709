import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {getCurrentUser, getUserDisplayName, isAdmin, isLoggedIn, isPabo, isSurfConext} from 'pages/Login/slice'
import ProfilePicture from 'components/ProfilePicture'
import Icon from 'components/Icon'

class UserAccountMenu extends React.PureComponent {
    state = {
        open: false,
        active: null,
    }

    open = () => {
        this.setState({open: true})
    }

    close = () => {
        this.setState({open: false})
    }

    setActive = (identifier) => () => {
        this.setState({ active: identifier })
    }

    resetActive = () => {
        this.setState({active: null})
    }

    isActive = (identifier) => this.state.active === identifier

    render() {
        const iconProps = {
            size: 22,
            style: {marginLeft: -5, marginRight: -18}
        }

        // If your school is a pabo, you can only see the account menu if you're an admin.
        // Fixes https://trello.com/c/Mcgoh4L4/
        const hasAccountMenu = this.props.isPabo ? this.props.isAdmin : true

        return (
            <div
                onMouseEnter={this.open}
                onMouseLeave={this.close}
                className="account__dropdown-menu tablet w-dropdown">
                <div className={'account__dropdown-toggle w-dropdown-toggle' + (this.state.open ? ' w--open' : '')}>
                    <ProfilePicture small user={this.props?.user}/>
                </div>
                <nav className={'account__dropdown-list w-dropdown-list' + (this.state.open ? ' w--open' : '')}>
                    <div className="account__triangle-contain">
                        <Icon name={'triangle'} width={15} height={10} className="account__triangle-image" />
                    </div>
                    <div className="account__username-contain">
                        <div className="dropdown__user-name">{
                            this.props.loggedIn
                                ? this.props.userDisplayName
                                : 'Welkom bij DramaOnline'
                        }</div>
                    </div>
                    {this.props.loggedIn === true && this.props.isSurfConext === false && (
                        <React.Fragment>
                            <Link to="/account" className="account__dropdown-link__mijn-account w-inline-block" onMouseEnter={this.setActive('account')} onMouseLeave={this.resetActive}>
                                <Icon name={'mijn-account'} active={this.isActive('account')} {...iconProps} />
                                <div className="dropdown__text-link">Mijn Account</div>
                            </Link>
                            {hasAccountMenu && (
                                <Link to="/abonnement" className="account__dropdown-link__abonnement w-inline-block" onMouseEnter={this.setActive('subscription')} onMouseLeave={this.resetActive}>
                                    <Icon name={'settings'} active={this.isActive('subscription')} {...iconProps} />
                                    <div className="dropdown__text-link">Abonnement</div>
                                </Link>
                            )}
                            {this.props.isAdmin && (
                                <Link to="/beheer" className="account__dropdown-link__gebruikers-beheer w-inline-block" onMouseEnter={this.setActive('users')} onMouseLeave={this.resetActive}>
                                    <Icon name={'gebruikers'} active={this.isActive('users')} {...iconProps} />
                                    <div className="dropdown__text-link">Gebruikersbeheer</div>
                                </Link>
                            )}
                        </React.Fragment>
                    )}
                    {this.props.loggedIn === false
                        ? (
                            <Link to="/" aria-current="page" className="account__dropdown-link__uitloggen w-inline-block w--current" onMouseEnter={this.setActive('logout')} onMouseLeave={this.resetActive}>
                                <Icon name={'uitloggen'} active={this.isActive('logout')} {...iconProps} />
                                <div className="dropdown__text-link">Inloggen</div>
                            </Link>
                        )
                        : (
                            <Link to="/uitloggen" aria-current="page" className="account__dropdown-link__uitloggen w-inline-block w--current" onMouseEnter={this.setActive('logout')} onMouseLeave={this.resetActive}>
                                <Icon name={'uitloggen'} active={this.isActive('logout')} {...iconProps} />
                                <div className="dropdown__text-link">Uitloggen</div>
                            </Link>
                        )
                    }
                </nav>
            </div>
        )
    }
}


export default connect(state => ({
    loggedIn: isLoggedIn(state),
    user: getCurrentUser(state),
    isAdmin: isAdmin(state),
    isSurfConext: isSurfConext(state),
    isPabo: isPabo(state),
    userDisplayName: getUserDisplayName(state),
}))(UserAccountMenu)