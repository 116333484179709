import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api, {standardApiSlice} from 'service/api'
import {contract, school, user} from 'service/schemas'

export const getSubscription = createAsyncThunk('subscription/get',
    api.get('/subscription/get', {
        schema: {
            contract: contract,
            school: school,
            user: user,
            admins: {data: [user]},
        }
    })
)

export const getSubscriptionSlice = createSlice(standardApiSlice('subscription', getSubscription))

export const { clearErrors } = getSubscriptionSlice.actions

export default getSubscriptionSlice.reducer