import React from 'react'
import PropTypes from 'prop-types'

class Overlay extends React.Component {
    static propTypes = {
        onParentComponent: PropTypes.bool,
    }

    static defaultProps = {
        onParentComponent: false,
    }

    render() {
        return (
            <div className={'overlay' + (this.props.onParentComponent ? ' overlay__on-component' : '')}>
                {this.props.children}
            </div>
        )
    }
}

export default Overlay