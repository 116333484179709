import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { getCookieConsentValue } from 'react-cookie-consent'

/**
 * Initialize Sentry
 */
function initSentry() {
    const hasConsented = getCookieConsentValue('consent') === 'true'

    if (!hasConsented) {
        // Did not consent, don't initiate sentry
        return
    }

    // Consented, initiate sentry
    try {
        Sentry.init({
            dsn: process.env.SENTRY_REACT_DSN,
            release: process.env.REACT_APP_SENTRY_RELEASE,
            autoSessionTracking: true,
            integrations: [
                new Integrations.BrowserTracing(),
            ],

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0,

            ignoreErrors: [
                /Loading chunk [\d]+ failed/,
                'Request aborted',
                'Network error',
                'AbortError',
                'ResizeObserver loop limit exceeded' // https://stackoverflow.com/a/50387233
            ],

            beforeSend: (event, hint) => {
                const error = hint?.originalException
                const chunkFailedMessage = /Loading chunk [\d]+ failed/
                if (chunkFailedMessage.test(error?.message)) {
                    if (confirm('Er is een nieuwe versie van DramaOnline beschikbaar. Wil je deze nieuwe versie gebruiken?')) {
                        window.location.reload(true) // 'true' is a deprecated option to try to force the browser to do a hard reload
                        return null
                    }
                }

                // Modify the event here
                if (event.user) {
                    // Don't send user's email address
                    delete event.user.email
                }
            }
        })
    } catch (e) {
        // if we cannot instantiate Sentry, because it's blocked by a privacy tracker (fair enough),
        // then we don't throw an exception. Instead, we just continue with Sentry error tracking disabled.
        // Hopefully fixes Sentry's REACT-P issue.
    }
}

export default initSentry