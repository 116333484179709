import {omitDataKeys} from 'service/util'

export const standardApiReducers = (api_call) => ({
    [api_call.fulfilled]: (state, action) => {
        state.loading = null
        state.error = null

        const normalized = action?.payload?.normalized
        if (normalized === null || normalized?.entities === null) {
            // there are no entities - the result was not normalized - so the 'success'
            // key will contain the full response payload
            state.success = action?.payload
        } else {
            // If the returned JSON was normalized (in api.post or api.get), then
            // the 'success' key will contain the id values of the normalized entities,
            // and the 'meta' key will contain the contents of the 'meta' information,
            // if you have provided it in your schema and if it is present
            state.success = omitDataKeys(normalized?.result)

            if (typeof normalized === 'object' && normalized !== null && 'meta' in normalized?.entities) {
                const meta = normalized?.entities?.meta
                const first_key = Object.keys(meta).pop()
                if (first_key in meta) {
                    state.meta = {...meta[first_key]}
                }
            }
        }
    },
    [api_call.pending]: (state, action) => {
        state.loading = 'pending'
    },
    [api_call.rejected]: (state, action) => {
        state.loading = 'error'
        state.error = action?.payload
        state.success = null
    },
})
