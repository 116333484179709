export const dropdownOptions = (list) => {
    return Object
        .keys(list)
        .map(key => ({label: list[key], value: key}))
}

/**
 * Given an array of schoolyear IDs, return an enum of schoolyears
 * with *just* these schoolyears.
 *
 * @param list
 * @returns {*}
 */
export const filteredSchoolyears = (list = []) => {
    return list
        .reduce(
            (result, curr) => ({...{[curr]: schoolyears[curr]}, ...result}),
            {}
        )
}

export const userTypes = {
    1: 'Beheerder',
    2: 'Schoolbeheerder',
    3: 'Docent'
}

export const schoolyears = {
    1: 'Groep 1/2',
    2: 'Groep 3',
    3: 'Groep 4',
    4: 'Groep 5',
    5: 'Groep 6',
    6: 'Groep 7',
    7: 'Groep 8',
}

export const techniques = {
    1: 'Pantomime',
    2: 'Improvisatie',
    3: 'Tableaus',
    4: 'Toneelspel',
    9: 'Bewegingsspel',
}

export const categories = {
    1: 'Concentratie',
    2: 'Samenwerking',
    3: 'Vertrouwen',
    16: 'Energie',
    17: 'Ontspanning',
}

export const exerciseDuration = {
    5: '5 min.',
    10: '10 min.',
    15: '15/20 min.',
}

export const groupSetup = {
    1: 'Klas',
    2: 'Groepjes',
    3: 'Tweetallen',
    4: 'Solo',
}

export const lessonDuration = {
    'kort': 'Kort moment',
    'uitgebreid': 'Uitgebreide les',
}

export const difficulty = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
}

export const discipline = {
    1: 'Beeldende kunst',
    2: 'Cultureel erfgoed',
    3: 'Muziek',
    4: 'Natuur & techniek',
    5: 'Literatuur',
    6: 'Mens & maatschappij',
    7: 'Rekenen',
    8: 'Feestdagen',
    10: 'Seizoenen',
    11: 'Sociale vaardigheden',
    12: 'Kleutermethodes',
    13: 'Levensbeschouwing',
    14: 'Geschiedenis',
}

export const creating = {
    1: 'Emoties spelen',
    2: 'Eigen leefwereld vormgeven',
    3: 'Fysiek spel',
    4: 'Stemgebruik',
    5: 'Rol spelen',
    6: 'Opbouw scène',
    7: 'Wie, wat, waar',
    8: 'Werken met teksten',
    9: 'Inspiratie',
}

export const presenting = {
    10: 'Reflecteren',
    11: 'Feedback geven',
    12: 'Kijken en luisteren',
    13: 'Jezelf presenteren',
    14: 'Groepsproces',
    15: 'Theatrale vormgeving',
}

export const socialSkills = {
    16: 'Respect',
    17: 'Keuzes maken',
    18: 'Gevoelens uiten',
    19: 'Samenwerken',
}