export const lightblue= '#3ccdff'
export const darkgrey= '#4c5c6b'
export const lightgreen= '#62be74'
export const lightgrey= '#798590'
export const red= '#8b0f61'
export const lightergrey= '#a1b1bf'
export const background_lightblue = '#d7ebfd'
export const background_green= '#ccffd5'
export const background_red= '#e8cfdf'
export const background_lightgrey= '#edeef0'
export const background_grey= '#dbdee1'
export const basically_white= '#f6f7f7'
export const white= '#fdfdfd'
export const black= '#222'