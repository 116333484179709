import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api, {standardApiSlice} from 'service/api'
import {exercise} from 'service/schemas'

const schema = {
    exercise: exercise
}

export const likeExercise = createAsyncThunk('exercise/like', api.post('/exercise/like', {schema}))
export const unlikeExercise = createAsyncThunk('exercise/unlike', api.post('/exercise/unlike', {schema}))
export const fetchExerciseDetails = createAsyncThunk('exercise/details', api.get('/exercise/details/:id', {schema}))

let standardSlice = standardApiSlice('like', [likeExercise, unlikeExercise, fetchExerciseDetails])

export const slice = createSlice(standardSlice)

export const { clearErrors } = slice.actions

export default slice.reducer