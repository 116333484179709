import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api, {standardApiSlice} from 'service/api'
import {homepage} from 'service/schemas'

export const fetchHomepage = createAsyncThunk('homepage/fetch', api.get('/homepage', {schema: homepage}))
export const fetchFutureHomepage = createAsyncThunk('homepage/fetchFuture', api.get('/homepage/preview/:id', {schema: homepage}))

let standardSlice = standardApiSlice(
    'home',
    [fetchHomepage, fetchFutureHomepage],
)

export const slice = createSlice(standardSlice)

export const {clearErrors} = slice.actions

export default slice.reducer