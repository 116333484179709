import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api, {standardApiSlice} from 'service/api'
import {lessonplan} from 'service/schemas'

const lessonplanList = {
    lessonplans: {data: [lessonplan]}
}

export const fetchLessonplans = createAsyncThunk('lessonplan/index', api.get('/lessonplan/index', {schema: lessonplanList}))
export const fetchLessonplanDetails = createAsyncThunk('lessonplan/details', api.get('/lessonplan/details/:id', {schema: lessonplan}))
export const deleteLessonFromPlan = createAsyncThunk('lessonplan/deleteLesson', api.delete('/lessonplan/:id/lesson/:lesson', {schema: lessonplan}))
export const addLessonToPlan = createAsyncThunk('lessonplan/addLesson', api.post('/lessonplan/lesson/add', {schema: lessonplan}))
export const updateLessonPlanTitle = createAsyncThunk('lessonplan/updateTitle', api.post('/lessonplan/title', {schema: lessonplan}))
export const createNewPlan = createAsyncThunk('lessonplan/create', api.post('/lessonplan/create', {schema: lessonplanList}))

export const reorderLessonsInPlan = createAsyncThunk('lessonplan/reorderLessons',
    (obj = {from: 0, to: 1, lessons: [], lessonplan: null}, thunkApi) => {
        // When we reorder the lessons within a lessonplan, we immediately update the Redux state ("optimistically"), assuming
        // that the API-call will succeed. It can take a while for the API to return the new order of lessons, and during that
        // time the frontend would flicker - the dragged lesson would briefly return to its old position. That's really ugly,
        // and we don't want that. Therefore we just go ahead and reorder the lessons ourselves, so that the frontend will
        // already display the new lesson order even though the API didn't agree to it yet.
        let lessons = obj.lessons.map(lesson => lesson.id)
        lessons.splice(obj.to, 0, lessons.splice(obj.from, 1)[0])

        thunkApi.dispatch({
            // we change the entities by firing an ad-hoc action with a specific structure
            type: 'lessonplan/reorderLessons/optimistically',
            payload: {normalized: {entities: {lessonplans: {[obj.lessonplan]: {lessons: lessons}}}}},
        })

        // now make the actual request
        delete obj.lessons // The API only needs 'lessonplan', 'from', and 'to'
        return api.post('/lessonplan/lesson/reorder', {schema: lessonplan})(obj, thunkApi)
    })


export const reorderPlans = createAsyncThunk('lessonplan/reorder',
    (obj = {plans: [], from: 0, to: 1}, thunkApi) => {
        // We need to 'optimistically' update the UI before we make the API call, otherwise
        // the user experience is really bad.
        //
        // First, determine the new order of the plans
        let ids = obj.plans.map(plan => plan.id)
        ids.splice(obj.to, 0, ids.splice(obj.from, 1)[0])

        // apply the new order to the lessons
        let reordered = obj.plans
            .map((plan, index) => ({...plan, sortorder: ids[index]}))
            // make sure we have an object, not an array (won't work otherwise)
            .reduce((res, plan) => ({...res, [plan.id]: plan}), {})

        // then, change the new sortorder of the plans in the entities
        thunkApi.dispatch({
            // we change the entities by firing an ad-hoc action with a specific structure
            type: 'lessonplan/reorder/optimistically',
            payload: {normalized: {entities: {lessonplans: reordered}}},
        })

        // finally, we do the API call that actually changes the sortorder on the server
        delete obj.plans // The API only needs 'from' and 'to'
        return api.post('/lessonplan/reorder', {schema: lessonplanList})(obj, thunkApi)
    })

export const deletePlan = createAsyncThunk('lessonplan/delete',
    (obj = {id: null, plans: []}, thunkApi) => {
        // delete the plan from the Redux store
        thunkApi.dispatch({
            type: 'lessonplan/delete/optimistically',
            payload: {normalized: {entities: {lessonplans: obj.plans.filter(plan => plan.id !== obj.id)}}}
        })

        // do the API call
        delete obj.plans
        return api.delete('/lessonplan/:id', {schema: lessonplanList})(obj, thunkApi)
    })


let standardSlice = standardApiSlice(
    'lessonplans',
    [fetchLessonplans, fetchLessonplanDetails, deleteLessonFromPlan, addLessonToPlan, updateLessonPlanTitle, deletePlan],
)


export const slice = createSlice(standardSlice)

export const {clearErrors} = slice.actions

export default slice.reducer