import loadable from '@loadable/component'

export const Triangle = loadable(() => import(/* webpackChunkName: "IconTriangle" */'./Triangle'))
export const ArrowLeftRed = loadable(() => import(/* webpackChunkName: "IconArrowLeftRed" */'./ArrowLeftRed'))
export const ArrowRightRed = loadable(() => import(/* webpackChunkName: "IconArrowRightRed" */'./ArrowRightRed'))
export const AudioFile = loadable(() => import(/* webpackChunkName: "IconAudioFile" */'./AudioFile'))
export const Add = loadable(() => import(/* webpackChunkName: "IconAdd" */'./Add'))
export const Close = loadable(() => import(/* webpackChunkName: "IconClose" */'./Close'))
export const Contact = loadable(() => import(/* webpackChunkName: "IconContact" */'./Contact'))
export const Delete = loadable(() => import(/* webpackChunkName: "IconDelete" */'./Delete'))
export const DeleteCircle = loadable(() => import(/* webpackChunkName: "IconDeleteCircle" */'./DeleteCircle'))
export const Download = loadable(() => import(/* webpackChunkName: "IconDownload" */'./Download'))
export const DownloadSimple = loadable(() => import(/* webpackChunkName: "IconDownloadSimple" */'./DownloadSimple'))
export const Print = loadable(() => import(/* webpackChunkName: "IconPrint" */'./Print'))
export const Fullscreen = loadable(() => import(/* webpackChunkName: "IconFullscreen" */'./Fullscreen'))
export const Edit = loadable(() => import(/* webpackChunkName: "IconEdit" */'./Edit'))
export const Favorite = loadable(() => import(/* webpackChunkName: "IconFavorite" */'./Favorite'))
export const Gebruikers = loadable(() => import(/* webpackChunkName: "IconGebruikers" */'./Gebruikers'))
export const Hulp = loadable(() => import(/* webpackChunkName: "IconHulp" */'./Hulp'))
export const Info = loadable(() => import(/* webpackChunkName: "IconInfo" */'./Info'))
export const Leerlijn = loadable(() => import(/* webpackChunkName: "IconLeerlijn" */'./Leerlijn'))
export const MijnAccount = loadable(() => import(/* webpackChunkName: "IconMijnAccount" */'./MijnAccount'))
export const Minus = loadable(() => import(/* webpackChunkName: "IconMinus" */'./Minus'))
export const Nieuw = loadable(() => import(/* webpackChunkName: "IconNieuw" */'./Nieuw'))
export const Notepad = loadable(() => import(/* webpackChunkName: "IconNotepad" */'./Notepad'))
export const Open = loadable(() => import(/* webpackChunkName: "IconOpen" */'./Open'))
export const Plus = loadable(() => import(/* webpackChunkName: "IconPlus" */'./Plus'))
export const Select = loadable(() => import(/* webpackChunkName: "IconSelect" */'./Select'))
export const Settings = loadable(() => import(/* webpackChunkName: "IconSettings" */'./Settings'))
export const Trash = loadable(() => import(/* webpackChunkName: "IconTrash" */'./Trash'))
export const Uitloggen = loadable(() => import(/* webpackChunkName: "IconUitloggen" */'./Uitloggen'))
export const Wifi = loadable(() => import(/* webpackChunkName: "IconWifi" */'./Wifi'))
export const LogoDramaonline = loadable(() => import(/* webpackChunkName: "IconLogoDramaonline" */'./LogoDramaonline'))
export const VideoPlayBt = loadable(() => import(/* webpackChunkName: "IconVideoPlayBt" */'./VideoPlayBt'))
export const Spinner = loadable( () => import(/* webpackChunkName: "IconSpinner" */'./Spinner'))
export const Search = loadable( () => import(/* webpackChunkName: "IconSearch" */'./Search'))