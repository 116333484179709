import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api, {standardApiSlice} from 'service/api'
import {lesson, meta} from 'service/schemas'

const lessonList = {
    list: {
        data: [lesson],
        meta: meta
    }
}

export const filterLessons = createAsyncThunk('lessons/filter', api.post('/lessons/filter', {schema: lessonList}))

const reducers = {
    setLessonFilterResults: (state, action) => {
        state.list = action?.payload?.list
        state.meta = action?.payload?.meta
    },
    resetLessonFilterResults: (state, action) => {
        state.list = []
        state.meta = {}
    }
}

let standardSlice = standardApiSlice('lessonfilter', [filterLessons], reducers)

export const slice = createSlice(standardSlice)

export const { clearErrors, setLessonFilterResults, resetLessonFilterResults } = slice.actions

export default slice.reducer