import React from 'react'

export const NetworkContext = React.createContext({})

export class OfflineDetection extends React.Component {

    state = {
        isOnline: true,
    }

    componentDidMount() {
        this.setState({
            isOnline: navigator.onLine
        })
        window.addEventListener('online', this.goOnline)
        window.addEventListener('offline', this.goOffline)
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.goOnline)
        window.removeEventListener('offline', this.goOffline)
    }

    goOnline = () => this.setConnected(true)
    goOffline = () => this.setConnected(false)

    setConnected = isOnline => this.setState({isOnline})

    render() {
        return (
            <NetworkContext.Provider value={{
                isOnline: this.state.isOnline,
                isOffline: !this.state.isOnline,
            }}>
                {this.props.children}
            </NetworkContext.Provider>
        )
    }
}

export const withOfflineDetection = ChildComponent => props => (
    <NetworkContext.Consumer>
        {
            context => (
                <ChildComponent
                    {...props}
                    network={context}
                />
            )
        }
    </NetworkContext.Consumer>
)