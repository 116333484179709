/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import initSentry from 'service/sentry'

window.axios = require('axios')

window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json'
}

window.axios.defaults.baseURL = (process.env.NODE_ENV !== 'production') ? 'https://dramaonline2020.test/api/' : '/api/'
window.axios.defaults.withCredentials = true // for Laravel Sanctum

/**
 * Initialize Laravel Sanctum
 */
window.axios.get('../sanctum/csrf-cookie')


/**
 * Register the service worker to make this app work offline.
 */
import * as serviceWorker from './src/service/serviceWorker/serviceWorkerRegistration'
serviceWorker.register({
    onUpdate: (registration) => window.dispatchEvent(new CustomEvent('dramaonline-sw-update', {detail: {registration: registration}})),
    onSuccess: () => window.dispatchEvent(new CustomEvent('dramaonline-sw-success')),
})


/**
 * Initialize Sentry error tracking
 */
initSentry()