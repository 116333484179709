import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import {fetchCurrentUser, getCurrentUser, isLoggedIn, isSuperAdmin, isSurfConext} from 'pages/Login/slice'
import loadable from '@loadable/component'
import Overlay from 'components/Overlay'
import ActivityIndicator from 'components/ActivityIndicator'
import Page from 'components/Page'
import {withOfflineDetection} from 'components/NetworkContext'
import OfflineNotification from 'components/OfflineNotification'
import SwitchWithFragments from 'components/SwitchWithFragments'
import {disableCopyPaste} from 'service/util'

const LoginPage = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "Login" */'pages/Login'))
const Logout = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "Logout" */'pages/Logout'))
const ContactPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "Contact" */'pages/Contact'))
const HelpPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "Help" */'pages/Help'))
const SubscriptionPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "Subscription" */'pages/Subscription'))
const AccountPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "Account" */'pages/Account'))
const UserManagementPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "UserManagement" */'pages/Members'))
const FilterExercisesPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "FilterExercises" */'pages/FilterExercises'))
const ExercisePage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "Exercise" */'pages/Exercise'))
const FilterLessonsPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "FilterLessons" */'pages/FilterLessons'))
const LessonPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "Lesson" */'pages/Lesson'))
const LessonPresentationPage = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "LessonPresentation" */'pages/LessonPresentation'))
const LessonPlanExplanationPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "LessonPlanExplanation" */'pages/LessonPlanExplanation'))
const LessonPlanListPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "LessonPlanList" */'pages/LessonPlanList'))
const LessonPlanPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "LessonPlan" */'pages/LessonPlan'))
const HomePage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "Home" */'pages/Home'))
const CreativeTwistPresentationPage = loadable( () => import(/* webpackPrefetch: true, webpackChunkName: "CreativeTwistPresentation" */'pages/CreativeTwistPresentation'))

class Router extends React.Component {
    state = {
        fetchedUser: false,
        showLoadingScreen: false,
    }

    componentDidMount() {
        // if fetching the user takes a long time, start showing a 'loading' screen
        setTimeout(() => this.setState({showLoadingScreen: true}), 2000)

        const fetchedUser = () => this.setState({fetchedUser: true})

        if (navigator.onLine) {
            // Fetch the user if we're online. If this request fails, the user will be logged out.
            this.props
                .dispatch(fetchCurrentUser())
                .then(fetchedUser)
        } else {
            // If we're not online, we don't fetch the user.
            fetchedUser()
        }

        if (!this.props.isSuperAdmin) {
            // disable copy-paste for everyone but the super user - see https://trello.com/c/AiX52q9R
            disableCopyPaste()
        }
    }

    render() {
        if (!this.state.fetchedUser) {
            // we haven't fetched the user yet
            if (!this.state.showLoadingScreen) {
                // we're still in the initial 2 second grace period - render nothing
                return null
            }

            // after a while, render the loading screen
            return (
                <Overlay>
                    <ActivityIndicator size={'large'} />
                </Overlay>
            )
        }

        // only once we fetched the user, we render the app
        return (
            <Page loggedIn={this.props.loggedIn}>
                <OfflineNotification />
                {!this.props.loggedIn
                    ? (
                        <Switch>
                            <Route path='/contact' component={ContactPage} />
                            <Route path='/hulp' component={HelpPage} />
                            <Route component={LoginPage} />
                        </Switch>
                    )
                    : (
                        <SwitchWithFragments>
                            <Route path="/" exact component={HomePage} />
                            {this.props.isSuperAdmin && <Route path="/preview/:id" component={HomePage} />}
                            <Route path="/kronkel/:id" exact component={CreativeTwistPresentationPage} />
                            <Route path="/login" component={LoginPage} />
                            <Route path="/uitloggen" component={Logout} />
                            <Route path="/lessen" render={(props) => (<FilterLessonsPage {...props} network={this.props.network} />)} />
                            <Route path="/les/presenteren/:id" component={LessonPresentationPage} />
                            <Route path="/les/:id" component={LessonPage} />
                            <Route path="/oefeningen" component={FilterExercisesPage} network={this.props.network} />
                            <Route path="/oefening/:id" component={ExercisePage} />
                            <Route path="/leerlijn/uitleg" component={LessonPlanExplanationPage} />
                            <Route path="/leerlijn/:id" component={LessonPlanPage} />
                            <Route path="/leerlijn" component={LessonPlanListPage} />
                            <Route path="/contact" component={ContactPage} />
                            <Route path="/hulp" component={HelpPage} />
                            {this.props.isSurfConext === false && (
                                <React.Fragment>
                                    { /* These routes are only available if you logged in with username & password */ }
                                    <Route path="/account" render={(props) => (<AccountPage {...props} type={'current'} />)} />
                                    <Route path="/abonnement" component={SubscriptionPage} />
                                    <Route path="/beheer/aanpassen/:id" render={(props) => (<AccountPage {...props} type={'edit'} />)} />
                                    <Route path="/beheer/toevoegen" render={(props) => (<AccountPage {...props} type={'add'} />)} />
                                    <Route path="/beheer" exact component={UserManagementPage} />
                                </React.Fragment>
                            )}
                            <Redirect to='/' /> {/* If none of the above matches, redirect to '/' */}
                        </SwitchWithFragments>
                    )
                }
            </Page>
        )
    }
}

export default connect(state => ({
    loggedIn: isLoggedIn(state),
    user: getCurrentUser(state),
    isSuperAdmin: isSuperAdmin(state),
    isSurfConext: isSurfConext(state),
}))(withOfflineDetection(Router))