import React from 'react'
import PropTypes from 'prop-types'
import Message from 'components/Message'
import Icon from 'components/Icon'
import {lightblue, red, lightgreen} from 'service/colors'

class Notification extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['error', 'default', 'success']),
        /** Timeout in seconds (!), at least 5 seconds */
        timeout: PropTypes.oneOf([PropTypes.number, null]),
        onClose: PropTypes.func,
    }

    static defaultProps = {
        type: 'default',
        timeout: null,
        onClose: () => {},
    }

    state = {
        visible: true,
    }

    autocloseTimer = null

    callbackTimer = null

    componentDidMount() {
        if (this.props?.timeout !== null) {
            const timeout = Math.min(this.props?.timeout, 5) * 1000
            if (this.autocloseTimer) clearTimeout(this.autocloseTimer)
            this.autocloseTimer = setTimeout(() => this.close(), timeout)
        }
    }

    componentWillUnmount() {
        if (this.autocloseTimer) clearTimeout(this.autocloseTimer)
        if (this.callbackTimer) clearTimeout(this.callbackTimer)
    }

    close = () => {
        if (this.state.visible === true) {
            this.setState({visible: false})

            if (this.autocloseTimer) clearTimeout(this.autocloseTimer)
            if (this.callbackTimer) clearTimeout(this.callbackTimer)
            this.callbackTimer = setTimeout(() => this.props.onClose(), 0)
        }
    }

    render() {
        if (!this.state.visible) {
            return null
        }

        const colors = { error: red, default: lightblue, success: lightgreen }

        return (
            <div className='notification'>
                <Message type={this.props.type}>
                    <div className='notification-inside'>
                        {this.props.children}
                        <Icon name={'delete-circle'}
                            onPress={this.close}
                            size={20}
                            primary={colors?.[this.props.type] || red}
                        />
                    </div>
                </Message>
            </div>
        )
    }
}

export default Notification