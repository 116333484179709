import React from 'react'
import PropTypes from 'prop-types'
import * as Icons from './icons'
import omit from 'fp-omit'

const iconlist = {
    'spinner': Icons.Spinner,
    'arrow-left-red': Icons.ArrowLeftRed,
    'arrow-right-red': Icons.ArrowRightRed,
    'add': Icons.Add,
    'close': Icons.Close,
    'open': Icons.Open,
    'plus': Icons.Plus,
    'minus': Icons.Minus,
    'contact': Icons.Contact,
    'delete-circle': Icons.DeleteCircle,
    'delete': Icons.Delete,
    'download': Icons.Download,
    'download-simple': Icons.DownloadSimple,
    'edit': Icons.Edit,
    'fullscreen': Icons.Fullscreen,
    'favorite': Icons.Favorite,
    'gebruikers': Icons.Gebruikers,
    'hulp': Icons.Hulp,
    'info': Icons.Info,
    'leerlijn': Icons.Leerlijn,
    'mijn-account': Icons.MijnAccount,
    'nieuw': Icons.Nieuw,
    'notepad': Icons.Notepad,
    'print': Icons.Print,
    'search': Icons.Search,
    'select': Icons.Select,
    'settings': Icons.Settings,
    'trash': Icons.Trash,
    'triangle': Icons.Triangle,
    'uitloggen': Icons.Uitloggen,
    'wifi': Icons.Wifi,
    'logo': Icons.LogoDramaonline,
    'video-play-bt': Icons.VideoPlayBt,
}

class Icon extends React.PureComponent {
    static propTypes = {
        name: PropTypes.oneOf(Object.keys(iconlist)).isRequired,
        primary: PropTypes.string,
        secondary: PropTypes.string,
        style: PropTypes.object,
        size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onPress: PropTypes.func,
        active: PropTypes.bool, // you can have a controlled component if you yourself manage the 'active' prop
        defaultActive: PropTypes.bool, // if you just want to set the default value for 'active', use this prop
        noHover: PropTypes.bool,
        inverted: PropTypes.bool,
    }

    static defaultProps = {
        active: undefined,
        size: 40,
        style: {},
        onPress: () => {},
        defaultActive: false,
        noHover: false,
        inverted: false,
    }

    state = {
        active: false,
    }

    isControlled = () => this.props.active !== undefined

    componentDidMount() {
        this.setState({
            active: this.props.defaultActive,
        })
    }

    setActive = (bool) => {
        if (!this.isControlled()) {
            this.setState({
                active: bool
            })
        }
    }

    onClick = (e) => {
        if (typeof this.props.onPress === 'function') {
            this.props.onPress(e)
        }
    }

    onMouseEnter = (e) => {
        this.setActive(!this.state.active)
        if ('onMouseEnter' in this.props && typeof this.props.onMouseEnter === 'function') {
            this.props.onMouseEnter(e)
        }
    }

    onMouseLeave = (e) => {
        this.setActive(!this.state.active)
        if ('onMouseLeave' in this.props && typeof this.props.onMouseLeave === 'function') {
            this.props.onMouseLeave(e)
        }
    }

    isSquareIcon = () => {
        // for non-square icons, we don't automatically assign the same default
        // width and height if the width and height are not provided via props
        return ['leerlijn', 'nieuw', 'fullscreen'].indexOf(this.props.name) === -1
    }

    render() {
        if (Object.keys(iconlist).indexOf(this.props.name) === -1) {
            // trying to render an icon that doesn't exist
            return null
        }

        const active = this.isControlled() ? this.props.active : this.state.active

        const width = this.props.width || (this.isSquareIcon() ? this.props.size : undefined)
        const height = this.props.height || (this.isSquareIcon() ? this.props.size : undefined)

        const IconComponent = iconlist[this.props.name]

        const props = omit(this.props,
            [
                ...Object.keys(Icon.propTypes),
                ...['style', 'className', 'onMouseEnter', 'onMouseLeave']
            ]
        )

        return (
            <div
                className={
                    `icon icon-${this.props.name} ` +
                    (this.state.active?` icon-active icon-${this.props.name}-active `:'') +
                    (this.props.noHover ? ' icon-no-hover':'') +
                    (this.props?.className || '')
                }
                onMouseOver={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onClick={this.onClick}
                style={{width: width, height: height, display: 'inline-block', ...this.props.style}}
            >
                <IconComponent
                    active={active}
                    primary={this.props.primary}
                    secondary={this.props.secondary}
                    inverted={this.props.inverted}
                    width={width}
                    height={height}
                    style={{pointerEvents: 'none'}}
                    {...props}
                />
            </div>
        )
    }
}

export default Icon
