import React from 'react'
import Icon from 'components/Icon'
import {getScrollPosition} from 'service/util'

class SearchButton extends React.Component {
    scrollToFilter = () => {
        const top = getScrollPosition(document.querySelector('.lessen__filter-contain'))
        window.scrollTo(
            {top: top, behavior: 'smooth'}
        )
    }

    render() {
        return (
            <div className={'not-wide'}>
                <Icon name={'search'} size={25} onPress={this.scrollToFilter}/>
            </div>
        )
    }
}

export default SearchButton