import {schema} from 'normalizr'
import {omitDataKeys} from 'service/util'

const options = {
    idAttribute: (value, parent, key) => value?.id || value?.data?.id || undefined,
    processStrategy: (value, parent, key) => omitDataKeys(value)
}

export const usertype = new schema.Entity('usertypes', {}, options)

export const userprofile = new schema.Entity('userprofiles', {
    usertype: usertype
}, options)

export const user = new schema.Entity('users', {
    profile: userprofile
}, options)

export const contracttype = new schema.Entity('contracttypes', {}, options)

export const contract = new schema.Entity('contracts', {
    contracttype: contracttype
}, options)

export const school = new schema.Entity('schools', {}, options)

export const category = new schema.Entity('categories', {}, options)

export const technique = new schema.Entity('techniques', {}, options)

export const lessoncomponenttype = new schema.Entity('lessoncomponenttypes', {}, options)

export const grouppartitioning = new schema.Entity('grouppartitionings', {}, options)

export const startposition = new schema.Entity('startpositions', {}, options)

export const schoolyear = new schema.Entity('schoolyear', {}, options)

export const meta = new schema.Entity('meta', {}, options)

export const audio = new schema.Entity('audio', {}, options)

export const videofiles = new schema.Entity('videofiles', {}, options)

export const videothumbs = new schema.Entity('videothumbs', {}, options)

export const video = new schema.Entity('video', {
    files: [videofiles],
    thumbs: [videothumbs]
}, options)

export const file = new schema.Entity('files', {}, options)

export const youtubeVideo = new schema.Entity('youtubevideos', {}, options)

export const exercise = new schema.Entity('exercises', {
    category: category,
    techniques: [technique],
    lessoncomponenttype: lessoncomponenttype,
    grouppartitioning: grouppartitioning,
    startposition: startposition,
    schoolyears: [schoolyear],
    audio: [audio],
    video: [video],
    files: [file],
    youtubevideos: [youtubeVideo],
}, options)

export const discipline = new schema.Entity('disciplines', {}, options)

export const goal = new schema.Entity('goals', {}, options)

export const lessoncomponent = new schema.Entity('lessoncomponents', {
    exercises: [exercise],
    componenttype: lessoncomponenttype,
}, options)

export const lesson = new schema.Entity('lessons', {
    schoolyears: [schoolyear],
    disciplines: [discipline],
    goals: [goal],
    technique: technique,
    documents: [file],
    components: [lessoncomponent]
}, options)

export const lessonplan = new schema.Entity('lessonplans', {
    author: user,
    lessons: [lesson],
}, options)

export const content = new schema.Entity('content', {
    author: user,
    image: file,
    audio: audio,
    video: video,
    youtubeVideo: youtubeVideo,
}, options)

export const page = new schema.Entity('page', {
    preview: content,
    article: content,
}, options)

export const featuredExercise = new schema.Entity('featuredexercise', {
    exercise: exercise
}, options)

export const featuredLesson = new schema.Entity('featuredlesson', {
    lesson: lesson,
}, options)

export const header = new schema.Entity('headers', {
    image: file,
}, options)

export const homepage = new schema.Entity('homepage', {
    lessons: [featuredLesson],
    exercises: [featuredExercise],
    creative_twists: [page],
    news: [page],
    webshop: [page],
    headers: [header],
}, options)

export const surfconext = new schema.Entity('surfconext', {
}, options)

export const landing = new schema.Entity('landing', {
    headers: [header],
    surfconext: [surfconext]
}, options)