// import "service/why-did-you-render"

import React from 'react'
import ReactDOM from 'react-dom'
import DramaOnlineRouter from './Router'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './store'
import Overlay from 'components/Overlay'
import ActivityIndicator from 'components/ActivityIndicator'
import ScrollMemory from 'react-router-scroll-memory'
import {OfflineDetection} from 'components/NetworkContext'
import SystemNotifications from 'components/SystemNotifications'
import {ComponentStateCache} from 'react-component-state-cache'
import {validateCSRFcookie} from 'service/api/api'
import CookieBar from 'components/CookieBar'

class AppWrapper extends React.Component {
    state = {
        rehydrated: false,
        showLoadingScreen: false,
    }

    store = null

    finishedHydrating = () => {
        this.setState({
            rehydrated: true
        })
    }

    componentDidMount() {
        // if rehydration takes a long time, start showing a 'loading' screen
        setTimeout(() => this.setState({showLoadingScreen: true}), 2000)

        // only start rendering the app once the previous state has been loaded from local storage
        // -> otherwise what might happen is that the router decides we're not logged in (because
        //    state says so), then redirects, even though it's not necessary because we actually
        //    *are* logged in - the router just doesn't know it yet. Which would mean you cannot
        //    reliably hard-reload URLs within the 'logged in' section, which is a bug.
        this.store = store(this.finishedHydrating) // call 'finishedHydrating' when initialized

        window.addEventListener('focus', this.onFocus)
    }

    componentWillUnmount() {
        window.removeEventListener('focus', this.onFocus)
    }

    onFocus = () => validateCSRFcookie()

    render() {
        if (!this.state.rehydrated) {
            // we haven't loaded the persisted state yet
            if (!this.state.showLoadingScreen) {
                return null
            }
            return (
                <Overlay>
                    <ActivityIndicator size={'large'} />
                </Overlay>
            )
        }

        // we've loaded all the persisted state, proceed to render the actual app
        return (
            <OfflineDetection>
                <BrowserRouter>
                    <ScrollMemory />
                    <Provider store={this.store}>
                        <ComponentStateCache>
                            <DramaOnlineRouter />
                            <SystemNotifications />
                            <CookieBar />
                        </ComponentStateCache>
                    </Provider>
                </BrowserRouter>
            </OfflineDetection>
        )
    }
}

export default AppWrapper


if (document.getElementById('app')) {
    ReactDOM.render(<AppWrapper />, document.getElementById('app'))
}
