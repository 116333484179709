import {combineReducers, configureStore} from '@reduxjs/toolkit'
import { offline } from '@redux-offline/redux-offline'
import merge from 'deepmerge'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults'
import loginSlice from 'pages/Login/slice'
import logoutSlice from 'pages/Logout/slice'
import registerSlice from 'pages/Register/slice'
import resetPasswordSlice from 'pages/ResetPassword/slice'
import requestPasswordResetSlice from 'pages/RequestPasswordReset/slice'
import contactSlice from 'pages/Contact/slice'
import subscriptionSlice from 'pages/Subscription/slice'
import accountSlice from 'pages/Account/slice'
import filterExercisesSlice from 'pages/FilterExercises/slice'
import likeExerciseSlice from 'pages/Exercise/slice'
import filterLessonsSlice from 'pages/FilterLessons/slice'
import lessonSlice from 'pages/Lesson/slice'
import lessonplanSlice from 'pages/LessonPlanList/slice'
import homeSlice from 'pages/Home/slice'
import surfconextSlice from 'pages/LoginStudents/slice'
import landingSlice from 'pages/Login/slice'

const isIntArray = array =>
    array.reduce((res, elem) =>
        res && (elem === undefined || typeof elem === 'number'),
    true
    )

// When receiving new entities, regular objects are MERGED, and
// integer-only arrays are OVERWRITTEN. Integer-only arrays are
// (only?) used for containing references to other entities.
//
// If we receive information about an entity, references to
// other entities are not appended, but overwritten - otherwise
// we either end up with more than one link to the same entity
// (say, in case we receive the same Exercise entity more than once).
// Additionally, we wouldn't be able to communicate that an entity
// is now no longer linked (you can't remove linked entities).
//
// So, in case of integer-only arrays: overwrite, not append.
const replaceIntArrays = (destinationArray, sourceArray) => {
    if (isIntArray(destinationArray) && isIntArray(sourceArray)) {
        return sourceArray
    }

    return [...destinationArray, ...sourceArray]
}

/**
 * If a request returns normalized data, merge it into the 'entities' slice.
 *
 * @param baseState
 * @param action
 * @returns {{}|*}
 */
function entitiesReducer(baseState = {}, { payload }) {
    if (!payload || !payload.normalized || !payload.normalized.entities) return baseState

    return merge(
        {...baseState},
        payload.normalized.entities,
        { arrayMerge: replaceIntArrays }
    )
}

const allReducers = combineReducers({
    entities: entitiesReducer,
    login: loginSlice,
    logout: logoutSlice,
    register: registerSlice,
    resetPassword: resetPasswordSlice,
    requestPasswordReset: requestPasswordResetSlice,
    contact: contactSlice,
    subscription: subscriptionSlice,
    account: accountSlice,
    exercises: filterExercisesSlice,
    like: likeExerciseSlice,
    lessons: filterLessonsSlice,
    lesson: lessonSlice,
    lessonplan: lessonplanSlice,
    home: homeSlice,
    surfconext: surfconextSlice,
    landing: landingSlice,
})

export default (persistCallback) => configureStore({
    reducer: (state, action) => {
        // In some cases we reset the state here. For how this works,
        // see https://medium.com/@asher.cassetto.cohen/e2a008d0de61
        if (action.type === 'login/logout/fulfilled') {
            // reset global application state on logout
            state = undefined
        }
        if (action.type === 'redux/resetStore') {
            // this resets all local state to its default values,
            // but keeps the current user session
            const {
                entities: { usertypes, userprofiles, users },
                login
            } = state

            state = { entities: { usertypes, userprofiles, users }, login }
        }
        return allReducers(state, action)
    },
    enhancers: (defaultEnhancers) => [
        offline({
            persistCallback: () => persistCallback(),
            ...offlineConfig
        }),
        ...defaultEnhancers
    ]
})
