import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api, {standardApiSlice} from 'service/api'
import {user} from 'service/schemas'
import {fetchUser} from 'pages/Login/slice'

const accountReply = {
    message: '',
    user: user,
}

const memberList = {
    user: {
        data: [user]
    }
}

export const updateAccount = createAsyncThunk('account/update', api.post('/account', {schema: accountReply}))

export const createAccount = createAsyncThunk('account/create', api.post('/account/create', {schema: accountReply}))

export const deleteAccount = createAsyncThunk('account/delete', api.delete('/account/:id', {schema: memberList}))

export const uploadProfilePicture = createAsyncThunk('account/picture', api.post('/account/picture', {schema: accountReply}))

export const getMembers = createAsyncThunk('account/list', api.get('account/index', {schema: memberList}))

let standardSlice = standardApiSlice('account', [updateAccount, createAccount, uploadProfilePicture, getMembers])

// if we cannot fetch the user, when loading the account screen, it needs to be known here
standardSlice.extraReducers[fetchUser.rejected] = (state, action) => {
    state.error = action?.payload
}

// Call this action to reset the redux store but keep the user session
export const resetReduxStore = createAction('redux/resetStore', () => ({payload: {}, meta: {}}))

export const slice = createSlice(standardSlice)

export const { clearErrors } = slice.actions

export default slice.reducer