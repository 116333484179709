import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api, {standardApiSlice} from 'service/api'
import {surfconext} from 'service/schemas'

const surfconextList = {
    data: [ surfconext ]
}

export const getSurfconextList = createAsyncThunk('list/surfconext', api.get('/list/surfconext', {schema: surfconextList}))

export const surfconextSlice = createSlice(standardApiSlice('surfconext', getSurfconextList))

export default surfconextSlice.reducer