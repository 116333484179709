import React from 'react'
import PropTypes from 'prop-types'
import ErrorFallback from 'components/ErrorFallback'
import Footer from 'components/Footer'
import NavBar from 'components/NavBar'
import {throttle} from 'throttle-debounce'
import * as Sentry from '@sentry/react'

export const PageContext = React.createContext({})

class Page extends React.Component {
    static propTypes = {
        isLoggedIn: PropTypes.bool.isRequired,
    }
    static defaultProps = {
        isLoggedIn: false,
    }

    static ScreenType = {
        MOBILE: 'MOBILE',
        TABLET: 'TABLET',
        DESKTOP: 'DESKTOP',
    }

    state = {
        navbar: true,
        searchButton: false,
        footer: true,
        footerShadow: true,
        className: null,
        currentSize: 0,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // if user logs in, the footer needs an additional class to add a shadow 🙄
        if (nextProps.isLoggedIn && prevState.footerShadow === false) {
            return {
                ...prevState,
                footerShadow: true,
            }
        }

        return {...prevState}
    }

    setConfig = (config) => {
        this.setState(config)
    }

    makeConfig = (keys, value) => (Array.isArray(keys) ? keys : [keys]).reduce((res, key) => ({...res, ...{[key]: value}}), {})

    /**
     * Sets the configuration value of the provided keys to 'false'.
     *
     * Allowed keys: 'navbar', 'footer', 'footerShadow', 'searchButton'
     *
     * @param keys
     */
    hide = (keys) => this.setConfig(this.makeConfig(keys, false))

    constructor(props) {
        super(props)
        this.onResize = throttle(100, false, this.onResize)
    }

    componentDidMount() {
        this.setState({
            currentSize: this.getSize(window.innerWidth)
        })
        window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
        this.onResize.cancel()
        window.removeEventListener('resize', this.onResize)
    }

    onResize = () => {
        const newSize = this.getSize(window.innerWidth)
        if (newSize !== this.state.currentSize) {
            this.setState({
                currentSize: newSize
            })
        }
    }

    getSize = (size) => {
        if (size <= 468) {
            return Page.ScreenType.MOBILE
        } else if (size >= 768) {
            return Page.ScreenType.DESKTOP
        } else {
            return Page.ScreenType.TABLET
        }
    }

    isMobile = () => this.state.currentSize === Page.ScreenType.MOBILE
    isTablet = () => this.state.currentSize === Page.ScreenType.TABLET
    isDesktop = () => this.state.currentSize === Page.ScreenType.DESKTOP

    /**
     * Sets the configuration value of the provided keys to 'true'
     *
     * Allowed keys: 'navbar', 'footer', 'footerShadow', 'searchButton'
     *
     * @param keys
     */
    show = (keys) => this.setConfig(this.makeConfig(keys, true))

    render() {
        const {children, ...props} = this.props
        return (
            <PageContext.Provider value={{
                hide: this.hide,
                show: this.show,
                configure: this.setConfig,
                screen: {
                    isMobile: this.isMobile,
                    isTablet: this.isTablet,
                    isDesktop: this.isDesktop,
                }
            }} {...props}>
                <div className={this.state.className ? this.state.className : null}>
                    {this.state.navbar && <NavBar withSearch={this.state.searchButton} />}
                    <Sentry.ErrorBoundary fallback={ErrorFallback}>
                        {children}
                    </Sentry.ErrorBoundary>
                    {this.state.footer && <Footer withShadow={this.state.footerShadow} />}
                </div>
            </PageContext.Provider>
        )

    }
}


// eslint-disable-next-line react/display-name
export const withPageContext = ChildComponent => props => (
    <PageContext.Consumer>
        {
            context => <ChildComponent {...props} page={context} screen={context.screen} />
        }
    </PageContext.Consumer>
)

export default Page