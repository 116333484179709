import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {logout} from 'pages/Logout/slice'
import api, {standardApiSlice} from 'service/api'
import {denormalize} from 'normalizr'
import {landing, user} from 'service/schemas'
import {schoolyears as allSchoolyears} from 'service/enums'
import * as Sentry from '@sentry/react'

export const login = createAsyncThunk('login/login', api.post('/login', { schema: user }))

export const fetchCurrentUser = createAsyncThunk('login/getCurrentUser', api.get('/user', { schema: user }))

// this is the same as fetchCurrentUser, with the one exception that requesting a user doesn't log you in as that user,
// it just stores it in the entities
export const fetchUser = createAsyncThunk('login/getUser', api.get('/user/:id', { schema: user }))

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        user: null,
        loading: null,
        error: null,
    },
    reducers: {
        clearErrors: (state) => {
            state.user = null
            state.loading = null
            state.error = null
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            const id = action?.payload?.normalized?.result // contains the user ID of the logged in user
            const users = action?.payload?.normalized?.entities?.users || {}
            Sentry.setUser({
                id: id,
                username: users?.[id]?.username || null
            })

            state.user = id
            state.loading = null
            state.error = null
        },
        [login.pending]: (state) => {
            state.user = null
            state.loading = 'pending'
            state.error = null
        },
        [login.rejected]: (state, action) => {
            state.user = null
            state.loading = 'error'
            state.error = action?.payload
        },
        [logout.fulfilled]: (state) => {
            state.user = null
        },
        [fetchCurrentUser.fulfilled]: (state, action) => {
            state.user = action?.payload?.normalized?.result // user ID of the logged in user
            state.loading = null
            state.error = null
        },
        [fetchCurrentUser.pending]: (state) => {
            state.loading = 'pending'
            state.error = null
        },
        [fetchCurrentUser.rejected]: (state, action) => {
            state.user = null // if we cannot fetch the current user, then there is no current user, and we need to logout
            state.loading = 'error'
            state.error = action?.payload
        },
    }
})

export const { clearErrors } = loginSlice.actions

const results = {}
export const getUserById = (id, state) => {
    // we remember the result of getUserById, so that we always return the exact same object when requesting
    // the same ID. This prevents unnecessary re-renders of whole sections of the DOM that depend on which
    // user is currently logged in.
    if (results && typeof results === 'object' && id in results && results[id]) {
        return results[id]
    }
    results[id] = denormalize(state?.entities?.users && state?.entities?.users[id], user, state?.entities)
    return results[id]
}

export const expireUserCache = (id) => {
    delete results?.[id]
}

export const getCurrentUser = state => getUserById(state?.login?.user, state)

export const isLoggedIn = state => typeof getCurrentUser(state) === 'object'

export const isSurfConext = state => (getCurrentUser(state)?.surfconext === true) || false

export const isPabo = state => (getCurrentUser(state)?.pabo === true) || false

export const isAdmin = state => getCurrentUser(state)?.profile?.usertype?.id < 3 // i.e. is school admin or super admin

export const isSuperAdmin = state => getCurrentUser(state)?.profile?.usertype?.id === 1

export const isSchoolAdmin = state => getCurrentUser(state)?.profile?.usertype?.id === 2

export const isTeacher = state => getCurrentUser(state)?.profile?.usertype?.id === 3

export const getVisibleSchoolyears = state => {
    // "no schoolyears" means you have access to *all* schoolyears
    // - see https://trello.com/c/zFTveuBP/ and https://trello.com/c/17mpqGuk/
    const list = getCurrentUser(state)?.profile?.schoolyears || []

    return list.length === 0 ? Object.keys(allSchoolyears).map(k => parseInt(k)) : list
}

export const isProfessionalUser = state => (getCurrentUser(state)?.professional === true) || false

export const isPersonalUser = state => (getCurrentUser(state)?.personal === true) || false

export const isTestUser = state => (getCurrentUser(state)?.test === true) || false

export const getUserDisplayName = state => isLoggedIn(state) ? getCurrentUser(state)?.profile?.full_name : 'DramaOnline gebruiker'

export default loginSlice.reducer





export const fetchLoginPageLayout = createAsyncThunk('login/landing', api.get('/loginpage/layout', { schema: landing }))

export const loginPageSlice = createSlice(standardApiSlice('landing', fetchLoginPageLayout))

export const loginPageSliceReducer = loginPageSlice.reducer

