import React from 'react'
import PropTypes from 'prop-types'
import {Link, NavLink, withRouter} from 'react-router-dom'
import {getCurrentUser, getUserDisplayName, isLoggedIn} from 'pages/Login/slice'
import {connect} from 'react-redux'
import UserAccountMenu from './components/UserAccountMenu'
import Icon from 'components/Icon'
import MobileMenu from './components/MobileMenu'
import {debounce} from 'throttle-debounce'
import SearchButton from './components/SearchButton'

class NavBar extends React.PureComponent {
    static propTypes = {
        withSearch: PropTypes.bool
    }
    static defaultProps = {
        withSearch: false,
    }

    state = {
        mobileMenuOpen: false,
    }

    toggleMobileMenu = debounce(50, false, () => {
        // we need to debounce opening the menu, it sometimes accidentally triggers
        // twice which results in the menu just 'flickering' instead of properly
        // opening
        this.setState({mobileMenuOpen: !this.state.mobileMenuOpen})
    })

    closeMobileMenu = debounce(50, false, () => {
        // we need to debounce opening the menu, it sometimes accidentally triggers
        // twice which results in the menu just 'flickering' instead of properly
        // opening
        this.setState({mobileMenuOpen: false})
    })

    render() {
        const isHome = this.props.location.pathname === '/'
        return (
            <header>
                <div data-collapse="medium" data-animation="default" data-duration="400" role="banner" className="navbar sticky w-nav">
                    <div className="navbar__contain settings">
                        <Link to="/" aria-current="page" className="brand w-nav-brand w--current">
                            <Icon name={'logo'} width={200} height={40} alt={'DramaOnline'} className="navbar__image"/>
                        </Link>
                        {!this.props.loggedIn && !isHome && (
                            <React.Fragment>
                                <UserAccountMenu />
                            </React.Fragment>
                        )}
                        {this.props.loggedIn && (
                            <>
                                <nav role="navigation" className="navbar__menu w-nav-menu">
                                    <NavLink exact to="/" className="navbar__link w-nav-link" activeClassName="w--current">Home</NavLink>
                                    <NavLink to="/lessen" className="navbar__link w-nav-link" activeClassName="w--current">Lessen</NavLink>
                                    <NavLink to="/oefeningen" className="navbar__link w-nav-link" activeClassName="w--current">Oefeningen</NavLink>
                                    <NavLink to="/leerlijn" className="navbar__link w-nav-link" activeClassName="w--current">Leerlijn</NavLink>
                                    <div data-hover="" data-delay="0" className="navbar__dropdown-menu w-dropdown">
                                        <div className="navbar__link w-dropdown-toggle">
                                            <div className="dropdown__icon-arrow w-icon-dropdown-toggle"/>
                                            <div>Instellingen</div>
                                        </div>
                                    </div>
                                </nav>
                                <UserAccountMenu/>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    {this.props.withSearch && (<SearchButton />)}
                                    <div className={'account__menu-button w-nav-button' + (this.state.mobileMenuOpen ? ' w--open' : '')} onClick={this.toggleMobileMenu}>
                                        <div className="menu__mobile w-icon-nav-menu"/>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {this.props.loggedIn && (
                        <MobileMenu open={this.state.mobileMenuOpen} close={this.closeMobileMenu} />
                    )}
                </div>
            </header>)
    }
}

export default connect(state => ({
    loggedIn: isLoggedIn(state),
    user: getCurrentUser(state),
    userDisplayName: getUserDisplayName(state),
}))(withRouter(NavBar))