import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api, {standardApiSlice} from 'service/api'
import {lesson} from 'service/schemas'

const schema = {
    lesson: lesson
}

export const likeLesson = createAsyncThunk('lesson/like', api.post('/lesson/like', {schema}))
export const unlikeLesson = createAsyncThunk('lesson/unlike', api.post('/lesson/unlike', {schema}))
export const fetchLessonDetails = createAsyncThunk('lesson/details', api.get('/lesson/details/:id', {schema}))
export const fetchLessonSummary = createAsyncThunk('lesson/summary', api.get('/lesson/summary/:id', {schema}))
export const saveLessonNotes = createAsyncThunk('lesson/notes/save', api.post('/lesson/notes', {schema}))
export const deleteLessonNotes = createAsyncThunk('lesson/notes/delete', api.delete('/lesson/notes/:id', {schema}))

const setCacheState = (is_cached) => (id) => {
    return {
        // in the lesson identified by 'id', set 'offline' to 'is_cached'
        payload: {normalized: {entities: {lessons: {[id]: {offline: is_cached}}}}},
        meta: {id}
    }
}

// Fire this action to indicate that a lesson is cached. It makes a change to the 'entities' by firing
// an action with a specific payload, which is then picked up by the entitiesReducer in store.js
export const cacheLesson = createAction('lesson/cacheLesson', setCacheState(true))

// Fire this action to indicate that a lesson has been removed from the cache
export const uncacheLesson = createAction('lesson/uncacheLesson', setCacheState(false))

let standardSlice = standardApiSlice(
    'lesson',
    [likeLesson, unlikeLesson, fetchLessonDetails],
)

export const slice = createSlice(standardSlice)

export const {clearErrors} = slice.actions

export default slice.reducer