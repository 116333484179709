import React from 'react'
import PropTypes from 'prop-types'

class ActivityIndicator extends React.Component {
    static propTypes = {
        style: PropTypes.any,
        width: PropTypes.number,
        height: PropTypes.number,
        size: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf(['small','medium','large'])
        ]),
        color: PropTypes.string,
    }
    static defaultProps = {
        style: {},
        width: null,
        height: null,
        size: 'medium',
        color: 'white',
    }
    render() {
        const dimensions = {small:12, medium:25, large:40}
        const size = dimensions[this.props.size] || this.props.size
        const width = this.props.width || size
        const height = this.props.height || size

        return (
            <div className='activity-indicator' style={{...this.props.style, ...{width, height}}}>
                <div className="la-ball-clip-rotate la-2x" style={{ color: this.props.color }}>
                    <div />
                </div>
            </div>
        )
    }
}

export default ActivityIndicator