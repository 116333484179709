import {schoolyears} from 'service/enums'
import {withRouter} from 'react-router-dom'
import React from 'react'

/**
 * Removes any 'data' keys from an object
 *
 * For example, this transforms
 *
 * {
 *     school: 12,
 *     user: 14,
 *     admins: {
 *         data: [10, 18]
 *     }
 * }
 *
 * into
 *
 * {
 *     school: 12,
 *     user: 14,
 *     admins: [10, 18]
 * }
 *
 * @param obj
 * @returns {*}
 */
export const omitDataKeys = (obj) => {
    if (typeof obj === 'object' && obj !== null) { // extra check because (typeof null === 'object')
        if ('data' in obj) {
            return omitDataKeys(obj.data)
        }
        Object.keys(obj).map(k => obj[k] = omitDataKeys(obj[k]))
    }
    if (Array.isArray(obj) && obj !== null) {
        obj.map(k => omitDataKeys(k))
    }
    return obj
}

/**
 * Comparison function to sort a list of users by usertype, last name, and first name - respectively.
 *
 * @param users
 * @returns []
 */
export const memberSort = (users = []) => users.sort(function(one, two) {
    if (one?.profile?.usertype?.id < two?.profile?.usertype?.id) {
        return -1
    } else if (one?.profile?.usertype?.id > two?.profile?.usertype?.id) {
        return 1
    } else if (one?.profile?.last_name < two?.profile?.last_name) {
        return -1
    } else if (one?.profile?.last_name > two?.profile?.last_name) {
        return 1
    } else if (one?.profile?.first_name < two?.profile?.first_name) {
        return -1
    } else if (one?.profile?.first_name > two?.profile?.first_name) {
        return 1
    }
    return 0
})

export const summarize = (labels = []) => {
    const unique = [...(new Set([...labels]))]
    const last = unique.pop()

    return (unique.length > 0
        ? (unique.join(', ') + ' en ' + last)
        : last
    )
}

export const unique = (value, index, array) => array.indexOf(value) === index

/**
 * Get a summary of schoolyears, e.g. 'Groep 3, 4 en 5'
 * @param ids
 * @param shorten
 * @returns {*}
 */
export const summarizeSchoolyears = (ids, shorten = false) => {
    const getLabel = (id) => id in schoolyears ? schoolyears[id].replace('Groep ', '') : null

    let years = ids
        .filter(unique)
        .map(getLabel)
        .filter(label => label !== null)
        .sort((a, b) => parseInt(a) - parseInt(b))

    if (years.length === 0) {
        return null
    }

    const last = years.pop()
    const summary = (
        (!shorten || (shorten && years.length < 4))
            ? 'Groep '
            : ''
    ) +
        (
            years.length > 0
                ? ( years.join(', ') + ' en ' + last )
                : last
        )

    return summary
}

/**
 * Given a size in pixels from the original design, return how large this same
 * element needs to be in the slightly smaller design we have now. We obtained
 * this smaller design by adjusting the default REM size from 16px to 14px.
 *
 * @param pixels
 * @returns {number}
 */
const rem = 14 // current base font size in pixels. Default in unstyled pages is 16.
export const scaled = (pixels) => pixels * rem / 16

/**
 * Given a DOM element <target>, check if it has a parent matching <selector>
 *
 * From https://stackoverflow.com/a/43938919
 */
export const hasParentWithMatchingSelector = (target, selector) =>
    [...document.querySelectorAll(selector)].some(el =>
        el !== target && el.contains(target)
    )

export const isClickableElement = (tag) => {
    // is this a valid link / clickable thing?
    const tagName = tag?.tagName?.toLowerCase()
    if (['a','embed','video','audio','img'].indexOf(tagName) > -1) {
        return true
    }

    // is this the video or the audio?
    const videoPlayerContainer = '.react-player-container'
    const className = tag?.getAttribute('class')
    if (className === videoPlayerContainer || hasParentWithMatchingSelector(tag, videoPlayerContainer)) {
        return true
    }

    return false
}

/**
 * Given a width and a height, either of which may be either undefined or given,
 * calculate the other value – based on the default width and the default height.
 *
 * @param width
 * @param height
 * @param defaultWidth
 * @param defaultHeight
 * @returns {{width: number, height: number}}
 */
export const scale = (width, height, defaultWidth, defaultHeight) => {
    const hasHeight = (height !== undefined)
    const hasWidth = (width !== undefined)

    const ratio = defaultWidth / defaultHeight

    if (hasHeight && !hasWidth) {
        width = ratio * height
    } else if (!hasHeight && hasWidth) {
        height = width / ( ratio )
    } else if (!hasHeight && !hasWidth) {
        width = defaultWidth
        height = defaultHeight
    } else if (hasHeight && hasWidth) {
        // nothing
    }

    return {width, height}
}

export const getScrollPosition = (element = {}) => {
    const headerOffset = 85
    const top = 'getBoundingClientRect' in (element || {}) ? (element?.getBoundingClientRect()?.top || 0) : 0
    const elementPosition = top + window.scrollY

    return elementPosition - headerOffset
}

export const withRouterAndRef = Wrapped => {
    const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
        <Wrapped ref={forwardRef} {...otherProps} />
    ))
    const WithRouterAndRef = React.forwardRef((props, ref) => (
        <WithRouter {...props} forwardRef={ref} />
    ))
    const name = Wrapped.displayName || Wrapped.name
    WithRouterAndRef.displayName = `withRouterAndRef(${name})`
    return WithRouterAndRef
}

export const isEmpty = (variable) => {
    return (Array.isArray(variable) && variable.length === 0) ||
        (typeof variable === 'object' && variable !== null && Object.keys(variable).length === 0) ||
        (typeof variable === 'string' && variable.trim().length === 0) ||
        (variable === null) ||
        (variable === undefined)
}

/**
 * Extract the 'id' parameter from the URL
 *
 * @param ownProps The props that were passed to the routed page
 * @param param (optional) The parameter to extract. Defaults to 'id'.
 * @returns {null|number} The value of the 'id' parameter, as an integer, or NULL.
 */
export const idFromUrl = (ownProps, param = 'id') => {
    if (ownProps?.match?.params) {
        if (param in (ownProps?.match?.params || {})) {
            return parseInt(ownProps?.match?.params?.[param])
        }
    }

    return null
}

/**
 * Do some insufficient HTML cleanup before it can be inserted into the DOM.
 *
 * @param html
 * @returns {string}
 */
export const cleanupHtml = (html) => {
    let div = document.createElement('div')
    div.innerHTML = html

    // remove some tags in a vain attempt at "improving" "security"
    const forbidden = ['script', 'style', 'link']
    forbidden.map(tag => {
        let elements = div.getElementsByTagName(tag)
        let i = elements.length
        while (i--) {
            elements[i].parentNode.removeChild(elements[i])
        }
    })

    // open external links in new window, see https://trello.com/c/MbokrbnV/
    let links = div.getElementsByTagName('a')
    for (let j = 0; j < links.length; j++) {
        if (links[j].hostname !== window.location.hostname) {
            links[j].setAttribute('target', '_blank')
        }
    }

    // done
    return div.innerHTML
}

/**
 * Vainly try to prevent people copy/pasting our lessons and exercises.
 *
 * See https://trello.com/c/AiX52q9R/
 */
export const disableCopyPaste = () => {
    // Disable copy-paste
    document.addEventListener('copy', function (evt) {
        evt.clipboardData.setData(
            'text/plain',
            'Je mag onze lessen en oefeningen niet zomaar kopiëren. Neem contact met ons op ' +
            '(via het contactformulier) als je onze lessen of oefeningen in een ander formaat wilt gebruiken.'
        )

        // Prevent the default copy action
        evt.preventDefault()
    }, false)

    // Prevent context menu from opening
    document.addEventListener('contextmenu', evt => evt.preventDefault(), false)

    // disable text-selection
    document.body.classList.add('noselect')
}