import React from 'react'
import PropTypes from 'prop-types'

function ErrorFallback(props) {
    return (
        <section>
            <div className="section-container">
                <div role="alert">
                    <p>Er is een fout opgetreden:</p>
                    <pre>{props.error.message}</pre>
                    {/* <button onClick={this.props.resetErrorBoundary}>Try again</button> */}
                </div>
            </div>
        </section>
    )
}

ErrorFallback.propTypes = {
    error: PropTypes.object,
    resetErrorBoundary: PropTypes.func,
}

ErrorFallback.defaultProps = {
    error: {},
    resetErrorBoundary: () => {
    }
}

export default ErrorFallback