import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api, {standardApiSlice} from 'service/api'
import {exercise, meta} from 'service/schemas'

const exerciseList = {
    list: {
        data: [exercise],
        meta: meta
    }
}

export const filterExercises = createAsyncThunk('exercises/filter', api.post('/exercises/filter', {schema: exerciseList}))

const reducers = {
    setExerciseFilterResults: (state, action) => {
        state.list = action?.payload?.list
        state.meta = action?.payload?.meta
    },
    resetExerciseFilterResults: (state, action) => {
        state.list = []
        state.meta = {}
    }
}

let standardSlice = standardApiSlice('exercises', [filterExercises], reducers)

export const slice = createSlice(standardSlice)

export const { clearErrors, setExerciseFilterResults, resetExerciseFilterResults } = slice.actions

export default slice.reducer