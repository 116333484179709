import React from 'react'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import {isAdmin, isLoggedIn, isSurfConext} from 'pages/Login/slice'
import {debounce} from 'throttle-debounce'

class MobileMenu extends React.Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired,
    }
    static defaultProps = {
        open: false,
        close: () => {},
    }

    state = {
        subOpen: false,
    }

    toggleSubmenu = debounce(50, false, () => {
        this.setState({subOpen: !this.state.subOpen})
    })

    close = () => {
        this.props.close()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // close submenu if the menu itself closes
        if (nextProps.open === false && prevState.subOpen === true) {
            return {
                ...prevState,
                subOpen: false,
            }
        }

        return {...prevState}
    }

    render() {
        const navProps = {
            className: 'navbar__link w-nav-link w--nav-link-open',
            activeClassName:  'w--current',
            onClick: this.close,
        }
        const submenuProps = {
            className: 'navbar__link indent w-dropdown-link',
            activeClassName:  'w--current',
            onClick: this.close,
        }
        const isOpen = this.props.open ? {'data-nav-menu-open': ''} : {}
        return (
            <div className="w-nav-overlay"
                style={this.props.open ? {height: '100vh', display: 'block'} : null}
            >
                <nav role="navigation"
                    className="navbar__menu w-nav-menu"
                    {...isOpen}
                >
                    <NavLink exact to="/"  aria-current="page" {...navProps}>Home</NavLink>
                    <NavLink to="/lessen" {...navProps}>Lessen</NavLink>
                    <NavLink to="/oefeningen" {...navProps}>Oefeningen</NavLink>
                    <NavLink to="/leerlijn" {...navProps}>Leerlijn</NavLink>
                    {this.props.isSurfConext
                        ? (
                            <NavLink to="/uitloggen" aria-current="page" {...navProps}>Uitloggen</NavLink>
                        )
                        : (
                            <div className="navbar__dropdown-menu w-dropdown w--nav-dropdown-open">
                                <div className="navbar__link w-dropdown-toggle w--nav-dropdown-toggle-open"
                                    id="w-dropdown-toggle-0"
                                    aria-controls="w-dropdown-list-0"
                                    aria-haspopup="menu"
                                    aria-expanded="false"
                                    role="button"
                                    tabIndex="0"
                                    style={{outline: 'currentcolor none medium'}}
                                    onClick={this.toggleSubmenu}
                                >
                                    <div className="dropdown__icon-arrow w-icon-dropdown-toggle" />
                                    <div>Instellingen</div>
                                </div>
                                <nav className={'navbar__dropdown-list w-dropdown-list' + (this.state.subOpen ? ' w--open':'')}>
                                    <NavLink to="/account" {...submenuProps}>Mijn account</NavLink>
                                    <NavLink to="/abonnement" {...submenuProps}>Abonnement</NavLink>
                                    {this.props.isAdmin && (<NavLink to="/beheer" {...submenuProps}>Gebruikersbeheer</NavLink>)}
                                    <NavLink to="/uitloggen" aria-current="page" {...submenuProps}>Uitloggen</NavLink>
                                </nav>
                            </div>
                        )
                    }
                </nav>
                <div style={{
                    zIndex: -1,
                    position:'fixed',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 100,
                    background:'rgba(0,0,0,0.3)'
                }} onClick={this.close} />
            </div>
        )
    }
}

export default connect(state => ({
    loggedIn: isLoggedIn(state),
    isAdmin: isAdmin(state),
    isSurfConext: isSurfConext(state),
}))(MobileMenu)