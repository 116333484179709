import React from 'react'
import CookieConsent from 'react-cookie-consent'
import initSentry from 'service/sentry'

class CookieBar extends React.Component {

    consented = () => {
        setTimeout(() => initSentry(), 0)
    }

    render() {
        return (
            <CookieConsent
                enableDeclineButton
                disableStyles
                containerClasses={'cookie-consent'}
                contentClasses={'cookie-consent-content'}
                buttonWrapperClasses={'cookie-consent-button-wrapper'}
                buttonClasses={'w-button button__red'}
                declineButtonClasses={'w-button button__grey'}
                onAccept={this.consented}
                location="bottom"
                buttonText="Geen probleem"
                declineButtonText="Niet ok"
                cookieName="consent"
                expires={60}
            >
                <div>
                    Wij gebruiken cookies om fouten in onze lesmethode snel te kunnen oplossen.&nbsp;
                    <span className={'cookie-consent-read'}>
                        Lees ons&nbsp;
                        <a href='https://dramaonline.nl/privacy-statement' target='_blank' rel='noreferrer'>privacy statement</a>.
                    </span>
                </div>
            </CookieConsent>
        )
    }
}

export default CookieBar
