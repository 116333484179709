import {standardApiReducers} from 'service/api/standardApiReducers'
import api from './api.js'

/**
 * Shallow merges two objects.
 *
 * @param a
 * @param b
 */
const merge = (a, b) => ({...a, ...b})

/**
 * The default reducers that operate on this slice
 */
const defaultReducers = {
    clearErrors: (state, action) => {
        state.loading = null
        state.error = null
        state.success = null
        state.meta = null
    }
}

/**
 * Creates a 'standard' API slice for use in createSlice that has a 'loading', 'error', 'success', and 'meta' state.
 *
 * @param name The name of the slice
 * @param api_call A single or array of api_call functions as created with createAsyncThunk()
 * @param reducers An object with reducers that operate on this slice
 * @param extra_reducers An object with reducers that operate on different slices than the current one
 * @returns {*}
 */
export const standardApiSlice = (name, api_call, reducers = {}, extra_reducers = {}) => {
    const apiCalls = Array.isArray(api_call) ? api_call : [api_call]

    return {
        name: name,
        initialState: {
            loading: null,
            error: null,
            success: null,
            meta: null,
        },
        reducers: merge(defaultReducers, reducers),
        extraReducers: merge(
            apiCalls.map(standardApiReducers).reduce(merge, {}),
            extra_reducers
        )
    }
}

export default api
