import React from 'react'
import PropTypes from 'prop-types'
import {
    Link
} from 'react-router-dom'
import Icon from 'components/Icon'

class Footer extends React.PureComponent {
    static propTypes = {
        withShadow: PropTypes.bool,
    }
    static defaultProps = {
        withShadow: false,
    }

    render() {
        return (
            <div className={'footer' + (this.props.withShadow ? ' shadow' : '')}>
                <div className="footer__contain">
                    <Icon name={'logo'} width={150} height={30} />
                    <Link to="/contact" id="w-node-36f0bcb9d6ca-bcb9d6c7" className="footer__link right-alligned w-inline-block">
                        <Icon name={'contact'} width={18} height={18} style={{marginRight: 10}} />
                        <div>Contact</div>
                    </Link>
                    <Link to="/hulp" id="w-node-36f0bcb9d6ce-bcb9d6c7" className="footer__link w-inline-block">
                        <Icon name={'hulp'} width={20} height={20} style={{marginRight: 10}} />
                        <div>Hulp</div>
                    </Link>
                    <div className="footer__copyright">©{new Date().getFullYear()} DramaOnline</div>
                </div>
            </div>
        )
    }
}

export default Footer